export const TYPES_MAPPING = {
  annelis: 'ANNELIS id',
  bibid: 'ESGBU - id bibliothèque',
  bnf: 'Identifiant BnF',
  'cnrs-unit': 'CNRS id',
  crunchbase: 'Crunchbase',
  cti: 'CTI id',
  dealroom: 'Dealroom',
  ed: "Numéro de l'ED",
  euTransparency: 'EU Transparency',
  eter: 'ETER id',
  etid: 'ESGBU - id établissement',
  finess: 'FINESS id',
  fundref: 'Crossref funder ID',
  googleScholar: 'GoogleScholar',
  grid: 'GRID id',
  hatvp: 'HATVP',
  idhal: 'HAL id',
  idref: 'idRef',
  isil: 'ISIL id',
  isni: 'ISNI',
  oc: 'OpenCorporate id',
  openAlexStructId: 'OpenAlex institution Id',
  openAlexPersonId: 'OpenAlex personne Id',
  openAlexTermId: 'OpenAlex terme Id',
  orgref: 'ORGREF id',
  pia: 'ANR - id projet PIA',
  'piaweb-organization': 'Id structure PiaWeb',
  'piaweb-project': 'Id projet PiaWeb',
  pic: 'PIC id',
  rcr: 'RCR id',
  ringgold: 'RINGGOLD id',
  rna: 'RNA id',
  rnsr: 'RNSR id',
  ror: 'ROR id',
  rtoad: 'RTOAD id',
  sdid: 'ESGBU - id service documentaire',
  siret: 'SIRENE - Siret',
  uai: 'UAI',
  wikidata: 'WIKIDATA id',
};
