import api from './api';

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export async function downloadFile(file) {
  const fileUrl = new URL(file.url).pathname;
  return api.get(fileUrl, { Accept: file.mimetype })
    .then((response) => response.blob())
    .then((blob) => {
      const downloadUrl = URL.createObjectURL(new Blob([blob], { type: file.mimetype }));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', file.originalName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}
