export const structuresHeadersMapping = {
  'Nom usuel en français': 'usualName',
  'Nom court en français': 'shortName',
  'Sigle en français': 'acronymFr',
  'Nom long en anglais :': 'nameEn',
  'Nom court en anglais :': 'acronymEn',
  'Nom officiel dans la langue originale': 'officialName',
  'Nom court officiel dans la langue originale': 'acronymLocal',
  'Autres dénominations possibles séparées par des ;': 'otherNames',
  'Statut [O = Ouvert, F = Fermé, P = Potentiel]': 'structureStatus',
  'Code de la/des catégories {rechercher le code, séparation ;}': 'categories',
  'Code du statut juridique {rechercher le code}': 'legalCategory',
  'Code commune {rechercher le code}': 'cityId',
  'Identifiant IdRef [123456789]': 'idref',
  'Identifiant Wikidata [Q1234]': 'wikidata',
  'Identifiant ROR [12cd5fg89]': 'ror',
  'Code UAI [1234567X]': 'uai',
  'Numéro siret [12345678901234]': 'siret',
  'Identifiant Crunchbase': 'crunchbase',
  'Identifiant Dealroom': 'dealroom',
  'Numéro RNSR [123456789X]': 'rnsr',
  "Numéro de l'ED [123]": 'ed',
  'Site internet en français': 'websiteFr',
  'Site internet en anglais': 'websiteEn',
  'Compte twitter [https://twitter.com/XXX]': 'twitter',
  'Compte linkedIn [https://www.linkedin.com/in/XXX-XXX-123456/]': 'linkedIn',
  'Adresse :': 'address',
  'Lieu dit :': 'place',
  'Boite postale :': 'postOfficeBoxNumber',
  'Code postal :': 'postalCode',
  "Localité d'acheminement :": 'locality',
  'Coordonnées GPS [-12.34,5.6789]': 'coordinates',
  'Nom du pays en français {rechercher le libellé}': 'country',
  'Code iso3 du pays {rechercher le code ISO}': 'iso3',
  'Date de création {2020-07-02}': 'creationDate',
  'Date de fermeture {2020-07-02}': 'closureDate',
  Parent: 'parentId',
};
